import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import { FormControl, InputLabel, FilledInput, Button } from '@material-ui/core'
import styled from 'styled-components';

const Styles = styled.div`
    .input-block {
        width: 50%;
        display: block;
        margin-bottom: 1.5rem;
    }
    .message-block {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    @media screen and (max-width: 500px) {
        .input-block {
            width: 50%;
        }
    }
`

export default function contact() {
    return (
        <div>
            <SEO title="Contact" />
            <Layout>
                <h2>Contact</h2>
                <form name="contact" method="POST" action="/success" data-netlify="true">
                    <Styles>
                        <input type="hidden" name="form-name" value="contact" />
                        <FormControl margin="normal" variant="filled" className="input-block">
                            <InputLabel htmlFor="name">Name</InputLabel>
                            <FilledInput id="name" name="name" type="text" />
                        </FormControl>
                        <FormControl margin="normal" variant="filled" className="input-block">
                            <InputLabel htmlFor="email">Email</InputLabel>
                            <FilledInput id="email" name="email" type="email" />
                        </FormControl>
                        <FormControl margin="normal" variant="filled" className="message-block">
                            <InputLabel htmlFor="message">Message</InputLabel>
                            <FilledInput id="message" name="message" multiline rows={10} />
                        </FormControl>
                        <Button 
                            type="submit"
                            variant="contained" 
                            color="primary" 
                            size="medium"
                            >
                            Send
                        </Button>
                    </Styles>
                </form>
            </Layout>
        </div>
    )
}
